export default function NewieWithTextLogo() {
    return (
        <svg  className={'translate-y-[2px]'} width="92" height="31" viewBox="0 0 92 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M36.3906 19.8049H38.9106V15.1609C38.9106 13.4689 39.8466 12.4249 41.5386 12.4249C43.1586 12.4249 43.9686 13.5049 43.9686 15.1249V19.8049H46.4886V14.5309C46.4886 11.9029 44.9226 10.1569 42.3666 10.1569C40.7826 10.1569 39.5946 10.7509 38.9106 11.5249V10.3369H36.3906V19.8049Z"
                fill="black"/>
            <path
                d="M53.5813 17.9509C51.9613 17.9509 50.7193 17.1409 50.4133 15.7729H58.8013V14.9809C58.8013 12.2809 56.8393 10.1569 53.5633 10.1569C50.2873 10.1569 47.9833 12.2809 47.9833 15.0709C47.9833 18.0049 50.3233 20.0029 53.5813 20.0029C56.0833 20.0029 58.0093 18.7069 58.6393 17.1409L56.4973 16.2229C56.0653 17.2669 54.9853 17.9509 53.5813 17.9509ZM53.4733 12.1729C55.1113 12.1729 56.1733 13.1809 56.3173 14.0629H50.5033C50.8993 12.8209 52.0873 12.1729 53.4733 12.1729Z"
                fill="black"/>
            <path
                d="M66.2182 10.3369L63.9682 16.7449L61.6102 10.3369H58.9282L62.6722 19.8049H64.9402L67.2622 13.5049L69.5842 19.8049H71.8522L75.5782 10.3369H72.9862L70.6822 16.7449L68.4142 10.3369H66.2182Z"
                fill="black"/>
            <path d="M76.6563 9.22089H79.2303V6.88089H76.6563V9.22089ZM79.2123 10.3369H76.6923V19.8049H79.2123V10.3369Z"
                  fill="black"/>
            <path
                d="M86.3596 17.9509C84.7396 17.9509 83.4976 17.1409 83.1916 15.7729H91.5796V14.9809C91.5796 12.2809 89.6176 10.1569 86.3416 10.1569C83.0656 10.1569 80.7616 12.2809 80.7616 15.0709C80.7616 18.0049 83.1016 20.0029 86.3596 20.0029C88.8616 20.0029 90.7876 18.7069 91.4176 17.1409L89.2756 16.2229C88.8436 17.2669 87.7636 17.9509 86.3596 17.9509ZM86.2516 12.1729C87.8896 12.1729 88.9516 13.1809 89.0956 14.0629H83.2816C83.6776 12.8209 84.8656 12.1729 86.2516 12.1729Z"
                fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1.44701 3.61354C0.5 5.24393 0.5 7.43575 0.5 11.8194V15.0644C0.5 19.448 0.5 21.6399 1.44701 23.2703C1.97243 24.1748 2.69276 24.9482 3.5532 25.5356C3.55159 25.5356 3.55078 25.5357 3.55078 25.5357C3.55078 25.5357 5.11825 26.7552 6.69726 27.6745C8.59698 28.7805 11.4061 30.3024 11.828 29.9899C12.0137 29.8523 11.8852 29.4176 11.7245 28.874C11.5299 28.2156 11.288 27.3974 11.5004 26.7542C11.7241 26.7544 11.9539 26.7544 12.19 26.7544H15.81C20.1936 26.7544 22.3855 26.7544 24.0159 25.8074C25.0676 25.1965 25.9421 24.322 26.553 23.2703C27.5 21.6399 27.5 19.448 27.5 15.0644V11.8194C27.5 7.43575 27.5 5.24393 26.553 3.61354C25.9421 2.56176 25.0676 1.68732 24.0159 1.0764C22.3855 0.129395 20.1936 0.129395 15.81 0.129395H12.19C7.80635 0.129395 5.61453 0.129395 3.98414 1.0764C2.93236 1.68732 2.05793 2.56176 1.44701 3.61354Z"
                  fill="#4350DE"/>
            <path
                d="M5.95801 14.3944C5.66643 14.3944 5.52064 14.3944 5.38766 14.443C5.15277 14.5288 4.93904 14.7707 4.88275 15.0143C4.85088 15.1523 4.86617 15.2756 4.89674 15.5224C5.14663 17.5393 6.06158 19.4282 7.51371 20.8803C9.23389 22.6005 11.5669 23.5669 13.9996 23.5669C16.4323 23.5669 18.7654 22.6005 20.4856 20.8803C21.9377 19.4282 22.8527 17.5393 23.1026 15.5224C23.1331 15.2756 23.1484 15.1523 23.1166 15.0143C23.0603 14.7707 22.8465 14.5288 22.6116 14.443C22.4787 14.3944 22.3329 14.3944 22.0413 14.3944V14.3944C21.7241 14.3944 21.5655 14.3944 21.4532 14.428C21.2137 14.4998 21.0738 14.6184 20.964 14.8431C20.9125 14.9484 20.8811 15.139 20.8181 15.5203C20.5844 16.9357 19.9139 18.2535 18.8863 19.281C17.5903 20.5771 15.8325 21.3052 13.9996 21.3052C12.1668 21.3052 10.409 20.5771 9.11299 19.281C8.08543 18.2535 7.41488 16.9357 7.18118 15.5203C7.11822 15.139 7.08675 14.9484 7.03527 14.8431C6.92546 14.6184 6.78561 14.4998 6.54605 14.428C6.43376 14.3944 6.27517 14.3944 5.95801 14.3944V14.3944Z"
                fill="white"/>
        </svg>

    )
}

export function NewieWithoutTextLogo() {
    return (
        <svg width="184" height="62" viewBox="0 0 92 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1.44701 3.61354C0.5 5.24393 0.5 7.43575 0.5 11.8194V15.0644C0.5 19.448 0.5 21.6399 1.44701 23.2703C1.97243 24.1748 2.69276 24.9482 3.5532 25.5356C3.55159 25.5356 3.55078 25.5357 3.55078 25.5357C3.55078 25.5357 5.11825 26.7552 6.69726 27.6745C8.59698 28.7805 11.4061 30.3024 11.828 29.9899C12.0137 29.8523 11.8852 29.4176 11.7245 28.874C11.5299 28.2156 11.288 27.3974 11.5004 26.7542C11.7241 26.7544 11.9539 26.7544 12.19 26.7544H15.81C20.1936 26.7544 22.3855 26.7544 24.0159 25.8074C25.0676 25.1965 25.9421 24.322 26.553 23.2703C27.5 21.6399 27.5 19.448 27.5 15.0644V11.8194C27.5 7.43575 27.5 5.24393 26.553 3.61354C25.9421 2.56176 25.0676 1.68732 24.0159 1.0764C22.3855 0.129395 20.1936 0.129395 15.81 0.129395H12.19C7.80635 0.129395 5.61453 0.129395 3.98414 1.0764C2.93236 1.68732 2.05793 2.56176 1.44701 3.61354Z"
                  fill="#4350DE"/>
            <path
                d="M5.95801 14.3944C5.66643 14.3944 5.52064 14.3944 5.38766 14.443C5.15277 14.5288 4.93904 14.7707 4.88275 15.0143C4.85088 15.1523 4.86617 15.2756 4.89674 15.5224C5.14663 17.5393 6.06158 19.4282 7.51371 20.8803C9.23389 22.6005 11.5669 23.5669 13.9996 23.5669C16.4323 23.5669 18.7654 22.6005 20.4856 20.8803C21.9377 19.4282 22.8527 17.5393 23.1026 15.5224C23.1331 15.2756 23.1484 15.1523 23.1166 15.0143C23.0603 14.7707 22.8465 14.5288 22.6116 14.443C22.4787 14.3944 22.3329 14.3944 22.0413 14.3944V14.3944C21.7241 14.3944 21.5655 14.3944 21.4532 14.428C21.2137 14.4998 21.0738 14.6184 20.964 14.8431C20.9125 14.9484 20.8811 15.139 20.8181 15.5203C20.5844 16.9357 19.9139 18.2535 18.8863 19.281C17.5903 20.5771 15.8325 21.3052 13.9996 21.3052C12.1668 21.3052 10.409 20.5771 9.11299 19.281C8.08543 18.2535 7.41488 16.9357 7.18118 15.5203C7.11822 15.139 7.08675 14.9484 7.03527 14.8431C6.92546 14.6184 6.78561 14.4998 6.54605 14.428C6.43376 14.3944 6.27517 14.3944 5.95801 14.3944V14.3944Z"
                fill="white"/>
        </svg>

    )
}
