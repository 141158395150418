import Bugsnag from "@bugsnag/js"

export const notifyBugsnag = async (error: unknown) => {
  const typedError =
    error instanceof Error
      ? error
      : new Error("Unknown error", { cause: error })

  Bugsnag.notify(typedError)
}
