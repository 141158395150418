"use client"

import Link from "next/link"
import AuthenticationDot from "@/components/site/AuthenticationDot"
import NewieWithTextLogo from "@/components/logos/svg/NewieWithText"
import { PropsWithChildren, useContext } from "react"
import { UserContext } from "@/context/UserContext"

export default function Header({ children }: PropsWithChildren) {
  const userContext = useContext(UserContext)

  const isProduction =
    process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID === "newie-production"
  const isStaffOrAdmin =
    userContext.userRole === "ADMIN" || userContext.userRole === "STAFF"

  const isAuthDotEnabled = !isProduction || isStaffOrAdmin

  return (
    <header
      className={
        "flex w-full items-center justify-center bg-white/[0.5] backdrop-blur-xl"
      }
    >
      <nav
        className={
          "flex w-full max-w-[1080px] flex-row justify-between border-b border-[#f1f1f1] py-[15px] align-middle sm:py-[25px]"
        }
      >
        <Link href="/" className={""}>
          <NewieWithTextLogo />
        </Link>
        {isAuthDotEnabled && <AuthenticationDot />}
        {children}
      </nav>
    </header>
  )
}
