"use client"
import { UserContext } from "@/context/UserContext"
import { admin_impersonate } from "@/firebase/clientApp"
import { useContext, useState } from "react"
import { Auth } from "firebase/auth"
import { signInWithCustomToken } from "@firebase/auth"
import { Functions } from "firebase/functions"
import { notifyBugsnag } from "@/support/bugsnag/bugsnags"
import { useFirebase } from "@/firebase/FirebaseProvider"

function handleSwitch(
  auth: Auth,
  functions: Functions,
  setIsLoadingSwitch: (isLoading: boolean) => void,
) {
  const impersonateUserID = prompt(
    "Provide UID to Impersonate, or leave blank to sign out.",
  )

  auth.onAuthStateChanged((user) => {
    if (user?.uid === impersonateUserID) {
      location.reload()
    }
  })

  if (impersonateUserID) {
    setIsLoadingSwitch(true)
    admin_impersonate(functions, { userID: impersonateUserID })
      .then(({ data: { token } }) => signInWithCustomToken(auth, token))
      .catch((err: unknown) => {
        console.log(err)
        if (typeof err === "string") {
          alert(err)
          return
        }

        alert(
          err && typeof err === "object" && "message" in err
            ? err.message
            : "Error performing switch",
        )
      })
      .finally(() => {
        setIsLoadingSwitch(false)
      })
  } else {
    auth.signOut().catch(notifyBugsnag)
  }
}

export default function AuthenticationDot() {
  const { auth, functions } = useFirebase()
  const userContext = useContext(UserContext)

  const [isLoadingSwitch, setIsLoadingSwitch] = useState(false)

  const isLoadingAuth =
    !userContext || userContext.loadingUser || isLoadingSwitch

  const isSignedOut = !isLoadingAuth && !userContext?.userV2

  const isSignedIn = !isLoadingAuth && userContext?.userV2

  return (
    <>
      {userContext && (
        <div className={"flex items-center justify-center"}>
          {isSignedOut && (
            <div
              data-testid={"AUTH_DOT"}
              className={"h-3 w-3 rounded-lg bg-red-500"}
            ></div>
          )}
          {isSignedIn && (
            <div
              data-testid={"AUTH_DOT"}
              onClick={() => {
                handleSwitch(auth, functions, setIsLoadingSwitch)
              }}
              className={"h-3 w-3 rounded-lg bg-green-500"}
            ></div>
          )}
          {isLoadingAuth && (
            <div
              data-testid={"AUTH_DOT"}
              className={"h-3 w-3 rounded-lg bg-blue-500"}
            ></div>
          )}
        </div>
      )}
    </>
  )
}
